import Storage from '@/resources/Storage'
import Environment from '@/resources/Environment'

class Preferences {
    static _slug = null

    static _getKey (name) {
        const env = Environment.getEnv()
        return `fernand.${env}.${this._slug}.${name}`
    }

    static setSlug (slug) {
        if (slug !== this._slug) {
            Storage.set('fernand.last_domain', slug)
            this._slug = slug
        }
    }

    static get slug () {
        return this._slug
    }

    static set (key, value) {
        Storage.set(this._getKey(key), value)
    }

    static get (key, fallback = null) {
        return Storage.get(this._getKey(key), fallback)
    }

    static del (key) {
        return Storage.get(this._getKey(key))
    }
}

export default Preferences
