import Storage from '@/resources/Storage'
import Preferences from '@/plugins/Preferences'

class AuthHandler extends Preferences {
    static init () {
        const lastDomain = Storage.get('fernand.last_domain')
        if (lastDomain && this._slug !== lastDomain) {
            this.setSlug(lastDomain)
        }
    }

    static setName (name) {
        this.set('name', name)
    }

    static setEmail (email) {
        this.set('email', email)
    }

    static setEnv (slug, email) {
        this.setSlug(slug)
        this.setEmail(email)
    }

    static connect (slug, token) {
        this.setSlug(slug)
        this.set('token', token)
    }

    static logout (slug = null) {
        if (!slug) {
            slug = this._slug
        }

        const base = this._getKey('').split('.').slice(0, 3).join('.')
        Object.keys(Storage._getStorageSystem()).filter(key => key.startsWith(base)).forEach(key => {
            Storage.remove(key)
        })
    }

    static getDomainFromRoute (to) {
        // Retrieve from to, or use the last one
        // Then check if token and slug exists
        // If yes, then return
        const lastDomain = Storage.get('fernand.last_domain')
        if (!lastDomain) return null

        this.setSlug(lastDomain)
        return Storage.get(this._getKey('token')) ? lastDomain : null
    }

    static get accounts () {
        const base = this._getKey('').split('.').slice(0, 2).join('.')

        const accounts = {}
        Object.keys(Storage._getStorageSystem()).forEach(key => {
            if (!key.startsWith(base)) return

            const parts = key.split('.')
            const slug = parts[2]
            const name = parts[3]

            if (!accounts[slug]) accounts[slug] = { slug }
            accounts[slug][name] = Storage.get(key)
        })

        return Object.values(accounts).filter(x => x.token && x.email)
    }

    static get token () {
        return this.get('token')
    }

    static get email () {
        return this.get('email')
    }
}

AuthHandler.init()

export default AuthHandler
