import Api from '@/resources/Api'

// Data related to "Bonjour" page and used at ConversationProgress
const Statistics = {
    namespaced: true,
    strict: true,
    state: () => ({
        statistics: {},
        defaultInbox: null,
        progress: {
            total: 0,
            processed: []
        },
        shadow: [] // List of tickets that will have an impact in the total. To avoid duplicates
    }),
    getters: {
        get: (state) => state.statistics,
        progress: (state) => state.progress
    },
    mutations: {
        SET_STATISTICS (state, payload) {
            state.statistics = {
                ...state.statistics,
                ...payload
            }

            const favicon = document.querySelector('link[rel="icon"]')
            if (favicon) {
                if (state.statistics[state.defaultInbox] === 0) {
                    if (favicon.href.indexOf('_unread.png') > -1) {
                        favicon.href = favicon.href.replace('_unread.png', '.png')
                    }
                } else {
                    if (favicon.href.indexOf('_unread.png') === -1) {
                        favicon.href = favicon.href.replace('.png', '_unread.png')
                    }
                }
            }
        },
        RESET_PROGRESS (state) {
            state.progress.total = state.statistics[state.defaultInbox]
            state.progress.processed = []
            state.shadow = []
        },
        RESET_STATE (state) {
            state.statistics = {}
            state.progress.total = 0
            state.progress.processed = []
            state.shadow = []
        },
        SET_DEFAULT_INBOX (state, inbox) {
            state.defaultInbox = inbox.key
        }
    },
    actions: {
        clear ({ commit }) {
            commit('RESET_STATE')
        },
        async load ({ state, commit, dispatch }, options) {
            /**
             * Options are:
             *     complete: boolean - Pass the "complete=true" request which will also count the total reading time of the default inbox
             *     reset: boolean - Reset the progress state
             */
            let url = '/conversations/status'
            if (options && options.complete) url += '?complete=true'

            try {
                const data = await Api.get(url)
                const params = {
                    ...data.statistics,
                    loaded: true // Used at Bonjour to show the statistics
                }
                if ('reading_time' in data) {
                    params.reading_time = data.reading_time || 0
                }
                commit('SET_STATISTICS', params)

                if (options?.reset) {
                    commit('RESET_PROGRESS')
                }
            } catch (error) {}
        },
        setDefaultInbox ({ commit }, inbox) {
            commit('SET_DEFAULT_INBOX', inbox)
        }
    }
}

export default Statistics
