import defaults, { baseState, buildEndpointUrl } from './utils'
import Api from '@/resources/Api'

const channels = {
    namespaced: true,
    state: baseState,
    getters: {
        ...defaults.getters,
        endpointUrl: (state) => (params) => buildEndpointUrl('organization/channels/', 'email', params),
        all: (state) => Object.values(state.items).filter(x => x !== null && !x.$removed)
    },
    mutations: {
        ...defaults.mutations,
        RESET_STATE (state) {
            Object.assign(state, baseState())
        }
    },
    actions: {
        ...defaults.actions,
        async verify ({ state, getters, commit }, id) {
            const instance = state.items[id]
            commit('UPDATE_ENTRY', { targetId: id, document: { $verifying: true } })
            const result = await Api.get(getters.endpointUrl({ instance }) + '/verify')
            // We have a successful response, we apply it to all the channels having the same domain
            getters.all.forEach(channel => {
                if (channel.id === id) {
                    commit('UPDATE_ENTRY', { targetId: channel.id, document: { ...result, $verifying: false } })
                } else {
                    commit('UPDATE_ENTRY', { targetId: channel.id, document: { domain: result.domain } })
                }
            })
            return result
        }
    }
}

export default channels
