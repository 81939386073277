import defaults, { baseState, buildEndpointUrl } from './utils'
import Api from '@/resources/Api'

const tags = {
    namespaced: true,
    state: baseState,
    getters: {
        ...defaults.getters,
        get: (state, getters) => (id, { resolve = null, reject = null } = {}) => {
            if (id == null) {
                if (resolve) resolve(null)
                return null
            }

            if (!(id in state.items)) {
                state.items[id] = null
            }

            if (resolve) resolve(state.items[id])
            return state.items[id]
        },
        all: (state) => Object.values(state.items).filter(x => x !== null).sort((a, b) => a.name.localeCompare(b.name)),
        endpointUrl: (state) => (params) => buildEndpointUrl('tags/', 'slug', params),
        findByName: (state) => (value) => {
            const key = Object.keys(state.items).find(key => {
                const item = state.items[key]
                if (!item) return null
                return item.name === value || item.slug === value
            })
            return state.items[key]
        }
    },
    mutations: {
        ...defaults.mutations,
        RESET_STATE (state) {
            Object.assign(state, baseState())
        }
    },
    actions: {
        ...defaults.actions,
        async remove ({ state, getters, commit }, { id, force = false }) {
            const targetId = state.mapId[id]
            const instance = state.items[targetId]

            let url = getters.endpointUrl({ instance })
            if (force) {
                url += '?force=true'
            }

            await Api.delete(url)
        }
    }
}

export default tags
