const Messenger = {
    namespaced: true,
    strict: true,
    state: () => ({
        liveContacts: {},
        intervals: {}
    }),
    getters: {
        contact: (state) => (contactId) => (contactId + '') in state.liveContacts ? state.liveContacts[contactId + ''] : null
    },
    mutations: {
        SET_CONTACT_HREF (state, { contactId, href = null }) {
            contactId += '' // Make sure it's a string
            if (contactId in state.intervals) {
                clearTimeout(state.intervals[contactId])
                state.intervals[contactId] = null
            }
            if (href) {
                state.liveContacts[contactId] = href
            } else if (contactId in state.liveContacts) {
                state.intervals[contactId] = setTimeout(() => {
                    delete state.liveContacts[contactId]
                }, 2000) // 2 seconds
            }
        }
    },
    actions: {
        live ({ commit }, params) {
            // Called automatically via the websocket
            const { href, contact_id } = params /* eslint-disable-line camelcase */
            commit('SET_CONTACT_HREF', { contactId: contact_id, href }) /* eslint-disable-line camelcase */
        },
        left ({ commit, getters }, params) {
            commit('SET_CONTACT_HREF', { contactId: params.contact_id }) /* eslint-disable-line camelcase */
        }
    }
}

export default Messenger
